.btn-fixed-end {
    left: 0;
    right: auto;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-fixed-start {
    right: 0;
    left: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btn-fixed-top {
    top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.btn-fixed-bottom {
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.btn-download{
    left: 0;
    right: unset;
}